document.addEventListener("DOMContentLoaded", function () {

    // ============================
    // Register GSAP Plugins
    // ============================
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    // let scrollSmoother = ScrollSmoother.create({
    //     smooth: 0,
    //     effects: true,
    //     smoothTouch: 0,
    //     // normalizeScroll: true
    // });



    // // ============================
    // // Initialize Smooth Scroll
    // // ============================
    // ScrollTrigger.normalizeScroll(false);



    // ============================
    // Function to Handle Scroll to Target
    // ============================
    const scrollElements = document.querySelectorAll('[data-scroll]');
        
    scrollElements.forEach(element => {
        const targetSelector = element.getAttribute('data-scroll');

        if (targetSelector) {
            const targetElement = document.querySelector(targetSelector);
            
            if (targetElement) {
                element.addEventListener('click', function (e) {
                    e.preventDefault();
                    gsap.to(window, {
                        scrollTo: targetElement,
                        duration: .6,
                        ease: "power4.inOut"
                    });
                });
            }
        }
    });
    

    // ============================
    // Header Behavior
    // ============================
    const header = document.querySelector("header");
    const headerToggle = document.querySelector(".header-toggle svg");
    const offcanvas = document.querySelector(".offcanvas");
    const searchForm = document.querySelector(".header-search__form");
    let lastScrollY = window.scrollY;

    window.addEventListener("scroll", () => {
        const isScrollingUp = window.scrollY < lastScrollY;

        // Toggle header visibility
        if (window.scrollY === 0) {
            header.classList.remove("scroll-up", "scroll-down");
        } else {
            header.classList.toggle("scroll-up", isScrollingUp);
            header.classList.toggle("scroll-down", !isScrollingUp);
        }

        // Reset offcanvas and header toggle on scroll down
        if (!isScrollingUp) {
            if (headerToggle.classList.contains("active")) {
                headerToggle.classList.remove("active");
            }
            if (offcanvas.classList.contains("expanded")) {
                offcanvas.classList.remove("expanded");
                animateOffcanvas(offcanvas); // Use animateOffcanvas for smooth reset
            }
            if (searchForm.classList.contains("search-opened")) {
                searchForm.classList.remove("search-opened");
            }
        }

        lastScrollY = window.scrollY;
    });



    // ============================
    // Get the header height and set it as --top on the main element
    // ============================
    function updateTopVariable() {
        const headerHeight = document.querySelector('header').getBoundingClientRect().height;
        document.querySelector('main').style.setProperty('--top', `${headerHeight}px`);
    }

    // Set initial --top value
    updateTopVariable();

    // Update on window resize
    window.onresize = updateTopVariable;



    // ============================
    // Search Toggle
    // ============================
    const searchToggle = document.querySelector("header .header-search__toggle");
    const headerSearch = document.querySelector("header .header-search__form");

    searchToggle.addEventListener("click", (event) => {
        headerSearch.classList.toggle("search-opened");
        event.stopPropagation(); // Prevent the click from bubbling to the document
    });

    // Close search form when clicking outside
    document.addEventListener("click", (event) => {
        if (!headerSearch.contains(event.target) && !searchToggle.contains(event.target)) {
            headerSearch.classList.remove("search-opened");
        }
    });



    // ============================
    // Splide Initialization
    // ============================
    document.querySelectorAll('[data-splide]').forEach(function (element) {
        try {
            const options = JSON.parse(element.getAttribute('data-splide') || '{}');
            const splide = new Splide(element, options);
            const extensions = options.grid ? { ...window.splide.Extensions } : undefined;

            // Generate a unique ID for this slider or use an existing one
            const sliderId = element.id || `spl-${Math.random().toString(36).substr(2, 5)}`;
            element.id = sliderId; // Assign ID if not already present
            SBS.Splides[sliderId] = splide; // Store the instance in SBS

            // Check for sliders that need to sync
            const syncTargets = element.getAttribute('data-sync');
            if (syncTargets) {
                const targetIds = syncTargets.split(',').map(id => id.trim());

                targetIds.forEach(targetId => {
                    const syncTarget = document.querySelector(`#${targetId}`);
                    if (syncTarget) {
                        const syncTargetSplide = syncTarget.splide || new Splide(syncTarget).mount();
                        splide.sync(syncTargetSplide);
                    } else {
                        console.warn(`Sync target with ID "${targetId}" not found.`);
                    }
                });
            }

            // Handle preview sliders (data-preview="next" or "prev")
            if (element.hasAttribute('data-splide-main')) {
                // Find all preview sliders
                const previewSliders = document.querySelectorAll('[data-preview]');
                
                splide.on('mounted', () => {
                    // Add click listeners to main slider arrows
                    const prevArrow = element.querySelector('.splide__arrow--prev');
                    const nextArrow = element.querySelector('.splide__arrow--next');

                    if (prevArrow && nextArrow) {
                        prevArrow.addEventListener('click', () => {
                            previewSliders.forEach(previewElement => {
                                const previewPrevArrow = previewElement.querySelector('.splide__arrow--prev');
                                if (previewPrevArrow) {
                                    previewPrevArrow.click(); // Trigger the arrow click on the thumbnail slider
                                }
                            });
                        });

                        nextArrow.addEventListener('click', () => {
                            previewSliders.forEach(previewElement => {
                                const previewNextArrow = previewElement.querySelector('.splide__arrow--next');
                                if (previewNextArrow) {
                                    previewNextArrow.click(); // Trigger the arrow click on the thumbnail slider
                                }
                            });
                        });
                    }
                });
            }

            splide.mount(extensions);
        } catch (error) {
            console.error('Invalid JSON in data-splide attribute:', error);
        }
    });



    // ============================
    // Glightbox Initialization
    // ============================
    if (document.querySelector('.media-lightbox')) {
        const lightbox = GLightbox({
            selector: '.media-lightbox',
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,
            videosWidth: '80vw',
            draggable: false,
            keyboardNavigation: false,
            touchNavigation: false,
            openEffect: 'show',
            closeEffect: 'show',
            cssEfects: {
                show: { in: 'showIn', out: 'showOut' }
            }
        });
    }



    // ============================
    // Offcanvas Functionality
    // ============================
    if (headerToggle && offcanvas) {
        headerToggle.addEventListener("click", () => {
            // Toggle 'expanded' class on the offcanvas
            const isExpanded = offcanvas.classList.toggle("expanded");

            // Toggle 'active' class on the header toggle
            headerToggle.classList.toggle("active", isExpanded);

            // Trigger the animation
            animateOffcanvas(offcanvas);
        });
    }



    // ============================
    // Offcanvas Menu Functionality
    // ============================
    const offcanvasMenu = document.querySelector(".offcanvas-menu");

    if (offcanvasMenu) {
        const subMenuItems = offcanvasMenu.querySelectorAll(".menu-item-has-children > a");

        subMenuItems.forEach((menuItem) => {
            const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("width", "7");
            svg.setAttribute("height", "5");
            svg.setAttribute("viewBox", "0 0 7 5");
            svg.setAttribute("fill", "none");
            svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            svg.innerHTML =
                '<path d="M1.094.5h4.21a.88.88 0 0 1 .795.5 1.05 1.05 0 0 1-.13 1.105l-2.105 2.55a.88.88 0 0 1-1.33 0L.429 2.105A1.05 1.05 0 0 1 .299 1a.88.88 0 0 1 .795-.5" fill="currentColor"/>';

            // Add the SVG to the menu item <a> tag
            menuItem.appendChild(svg);

            svg.addEventListener("click", function (e) {
                e.preventDefault();

                const parentItem = menuItem.parentElement; // Get the parent <li>
                const subMenu = parentItem.querySelector(".sub-menu");

                if (subMenu) {
                    menuItem.classList.toggle("expanded");
                    parentItem.classList.toggle("expanded");
                    slideToggle(subMenu, 0.6);
                }
            });
        });
    }



    // ============================
    // Animate Product Items & Vacancy List
    // ============================
    animateElements('.products-content__item');
    animateElements('.news-content__item');
    animateElements('.search-content__item');



    // =========================
    // Toggle Animation for Vacancy List
    // =========================
    const vacancyList = document.querySelector('.vacancy-list');

    if (vacancyList) {
        vacancyList.addEventListener('click', (event) => {
            const cardHead = event.target.closest('.vacancy-list__card-head');
            
            if (cardHead) {
                const item = cardHead.closest('.vacancy-list__item');
                const cardBody = item.querySelector('.vacancy-list__card-body');
                
                if (cardBody) {
                    item.classList.toggle('expanded');
                    slideToggle(cardBody, 0.6);
                }
            }
        });
    }




    // ============================
    // Vacancy Form Position Select & ScrollTo
    // ============================
    const applyButtons = document.querySelectorAll('.vacancy-list__card-btn');

    applyButtons.forEach(button => {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            const postId = this.dataset.id;

            const jobForm = document.querySelector('.job-form');
            const positionSelect = jobForm.querySelector('select');
            const nameInput = jobForm.querySelector('input[name="name[first_name]"]');
            if (positionSelect) {
                positionSelect.value = postId;
                positionSelect.dispatchEvent(new Event('change'));

                gsap.to(window, {
                    scrollTo: jobForm,
                    duration: .7,
                    ease: "power4.inOut",
                    onComplete: () => {
                        if (nameInput) {
                            nameInput.focus();
                        }
                    }
                });
            }
        });
    });



    // ============================
    // Synchronize Select with Splides
    // ============================
    const locationDropdown = document.getElementById("location");

    if (locationDropdown) {
        locationDropdown.addEventListener("change", () => {
            const selectedIndex = locationDropdown.selectedIndex;
            Object.values(SBS.Splides).forEach((splideInstance) => {
                splideInstance.go(selectedIndex);
            });
        });
    }


    
});



window.WP_Grid_Builder?.on("init", (wpgb) => {
    wpgb.facets?.on("appended", function (args) {
        let isAnimating;

        const productsContent = document.querySelector('.products-content');
        const newsContent = document.querySelector('.news-content');
        const searchContent = document.querySelector('.search-content');
        
        if (isAnimating) {
            //isAnimationg is coming from the functions line 108

            const checkAnimationInterval = setInterval(() => {
                if (!isAnimating) {
                    clearInterval(checkAnimationInterval);
                    const newContent = args[0]?.node || document;
                    if (productsContent) {
                        animateElements(".products-content__item", newContent);
                    }
                    if (newsContent) {
                        animateElements(".news-content__item", newContent);
                    }

                }
            }, 10);
        } else {
            const newContent = args[0]?.node || document;
            if (productsContent) {
                animateElements(".products-content__item", newContent);
            }
            if (newsContent) {
                animateElements(".news-content__item", newContent);
            }
            if (searchContent) {
                animateElements(".search-content__item", newContent);
            }
        }
    });
});